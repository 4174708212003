<template>
  <div>
    <HeaderEmpresa :empresa="empresa" :mostrarDatos="false" :showAdministrarTienda="true" />

    <div class="sucursales container">
      <div v-for="sucursal in sucursales" @click="onSucursalClick(sucursal)" class="sucursal box">
        <i class="fa fa-store"></i>

        <div class="nombre">
          {{ sucursal.nombre_fantasia }}
        </div>
        <div class="ubicacion">
          <i class="fa fa-map-marker-alt"></i>
          {{ sucursal.domicilio }}, {{ sucursal.localidad }}
        </div>
        <div v-if="sucursal.leyenda_horario" class="horario">
          <i class="fa fa-clock"></i>
          {{ sucursal.leyenda_horario }}
        </div>
        <div v-if="sucursal.descripcion" class="descripcion">
          {{ sucursal.descripcion }}
        </div>
      </div>
    </div>

    <div id="impulsado-por">
      <a href="/" target="_blank">
        {{ I18n.impulsado_por }} <i class="fa fa-bolt"></i> AgilPedido
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HeaderEmpresa from '@src/tienda/components/header_empresa'

export default {
  components: { HeaderEmpresa },

  data() {
    return {
      I18n: serverExports.I18n
    }
  },

  computed: {
    ...mapState(['empresa', 'sucursales'])
  },

  methods: {
    onSucursalClick(sucursal) {
      this.$router.push({
        name: 'PrepararPedidoSucursal',
        params: {
          tienda: this.$route.params.tienda,
          sucursal: sucursal.url
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 1rem;
  max-width: 1024px !important;
}

@media (max-width: 1024px) {
  .container {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

.box {
  border: 1px solid #eee;
  padding: 0.75rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.nombre {
  font-weight: bold;
  margin-top: 0.5rem;
}

.ubicacion,
.horario,
.descripcion {
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

i.fa {
  margin-right: 0.2rem;
}

i.fa-store {
  font-size: 2.5rem;
}

#impulsado-por {
  text-align: center;
  font-size: 0.7rem;
  margin-top: 1rem;
  margin-bottom: 0.75rem;

  a {
    color: black;
  }

  i.fa {
    margin-left: 0.3rem;
  }
}
</style>
