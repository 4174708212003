<template>
  <div>
    <HeaderEmpresa :empresa="empresa" :mostrarDatos="false" />

    <div class="container">
      <h2 style="font-weight: bold; text-align: center; margin-top: 1rem; margin-bottom: 1rem">
        <i class="fa fa-user" style="margin-right: 0.5rem"></i>
        {{ I18n.completar_datos }}
      </h2>

      <div class="field">
        <label class="label" for="nombre">{{ I18n.nombre }}*:</label>
        <div class="control">
          <input
            :value="pedido.nombre"
            @input="SET_RECEPTOR($event.target.value)"
            ref="nombre"
            type="text"
            id="nombre"
            class="input"
            maxlength="50"
          />
        </div>
      </div>

      <template v-if="pedido.envioDomicilio">
        <div class="field">
          <label class="label" for="domicilio">{{ I18n.domicilio }}*:</label>
          <div class="control">
            <input
              :value="pedido.domicilio"
              @input="SET_DOMICILIO($event.target.value)"
              type="text"
              id="domicilio"
              class="input"
              maxlength="50"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="entre-calles">{{ I18n.entre_calles }}:</label>
          <div class="control">
            <input
              :value="pedido.entreCalles"
              @input="SET_ENTRE_CALLES($event.target.value)"
              type="text"
              id="entre-calles"
              class="input"
              maxlength="50"
            />
          </div>
        </div>
      </template>

      <div
        v-for="(preguntaVisitante, index) in preguntas_visitante"
        :key="preguntaVisitante.id"
        class="field pregunta_visitante"
      >
        <label class="label" :for="`pregunta_visitante_${index}`">
          {{ preguntaVisitante.titulo }}<span v-if="preguntaVisitante.requerido">*</span>:
        </label>
        <div class="control">
          <input
            :value="preguntaVisitante.respuesta"
            @input="SET_RESPUESTA_PREGUNTA({ index, respuesta: $event.target.value })"
            :id="`pregunta_visitante_${index}`"
            :placeholder="preguntaVisitante.sugerencia"
            type="text"
            class="input"
            maxlength="50"
          />
        </div>
        <div v-if="preguntaVisitante.ayuda.length > 0" class="help">
          {{ preguntaVisitante.ayuda }}
        </div>
      </div>

      <div class="field">
        <label class="label" for="comentario">{{ I18n.comentario }}:</label>
        <div class="control">
          <textarea
            :value="pedido.comentario"
            :placeholder="leyendaComentario"
            @input="SET_COMENTARIO($event.target.value)"
            id="comentario"
            rows="2"
            class="textarea"
          >
          </textarea>
        </div>
      </div>

      <div v-if="empresa.cupones_descuento.length > 0" class="field">
        <label class="label" for="cupon-descuento">Cupón de descuento:</label>
        <div class="control">
          <input
            :value="pedido.cuponDescuento"
            @input="SET_CUPON_DESCUENTO($event.target.value)"
            type="text"
            id="cupon-descuento"
            class="input"
            maxlength="50"
          />
        </div>
      </div>

      <div id="totales-container">
        <div id="total-pedido" :class="{ tachado: cuponDescuentoAplicado }">
          <div><i class="fa fa-fw fa-shopping-cart"></i>{{ I18n.total_pedido }}</div>
          <div>
            {{ totalPedido | formatDecimal }}
          </div>
        </div>

        <div id="total-descuento" v-if="cuponDescuentoAplicado">
          <div class="has-text-danger">
            <i class="fa fa-fw fa-tag"></i>Descuento
            <small class="ml-2"> ( {{ cuponDescuentoAplicado.descuento }}% ) </small>
          </div>
          <div class="has-text-danger">- {{ totalDescuento | formatDecimal }}</div>
        </div>

        <div id="total-pedido-con-descuento" v-if="cuponDescuentoAplicado">
          <div><i class="fa fa-fw fa-cart-arrow-down"></i>Total final</div>
          <div>
            {{ totalPedidoConDescuento | formatDecimal }}
          </div>
        </div>
      </div>

      <label v-if="empresa.mercadopago_habilitado" class="forma-pago checkbox">
        <input @click="onGenerarLinkMercadoPagoClick($event.target.checked)" type="checkbox" />
        <span id="leyenda-mercado-pago">{{ I18n.pagar_mercadopago }}</span>
        <i v-show="requestLinkMercadoPago" class="fa fa-lg fa-spinner fa-spin"></i>
        <i v-show="successLinkMercadoPago" class="fa fa-check-circle has-text-success"></i>
        <i v-show="failureLinkMercadoPago" class="fa fa-times-circle has-text-danger"></i>
      </label>

      <label v-if="empresa.wompi_habilitado" class="forma-pago checkbox">
        <input @click="onGenerarLinkWompiClick($event.target.checked)" type="checkbox" />
        <span id="leyenda-wompi-pago">Quiero pagar por Wompi</span>
        <i v-show="requestingLinkWompi" class="fa fa-lg fa-spinner fa-spin"></i>
        <i
          v-show="!requestingLinkWompi && pedido.pagoWompi && successLinkWompi"
          class="fa fa-check-circle has-text-success"
        ></i>
        <i
          v-show="!requestingLinkWompi && pedido.pagoWompi && !successLinkWompi"
          class="fa fa-times-circle has-text-danger"
        ></i>
      </label>

      <a
        :href="habilitarEnviarWhatsApp ? linkWhatsApp : '#'"
        :disabled="!habilitarEnviarWhatsApp"
        @click="onLinkWhatsAppClick"
        class="button is-primary"
        style="margin-top: 1.5rem; width: 100%; font-size: 1rem; font-weight: bold"
        target="_blank"
      >
        <i class="fab fa-whatsapp" style="margin-right: 0.75rem"></i>
        {{ I18n.enviar_whatsapp }}
      </a>
      <button
        @click.prevent="onVolverPedido"
        class="button is-warning"
        style="margin-top: 1rem; width: 100%; font-size: 1rem; font-weight: bold"
      >
        <i class="fa fa-undo" style="margin-right: 0.75rem"></i>
        {{ tituloBotonVolver }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import HeaderEmpresa from '@src/tienda/components/header_empresa'

export default {
  components: { HeaderEmpresa },

  data() {
    return {
      requestLinkMercadoPago: false,
      successLinkMercadoPago: false,
      failureLinkMercadoPago: false,
      requestingLinkWompi: false,
      successLinkWompi: false,
      I18n: serverExports.I18n
    }
  },

  created() {
    if (this.productosPedido.length == 0)
      this.$router.push({ name: 'PrepararPedido', params: { empresa: this.$route.params.empresa } })
  },

  mounted() {
    this.$refs.nombre.focus()
  },

  computed: {
    ...mapState(['empresa', 'pedido', 'preguntas_visitante']),
    ...mapGetters([
      'totalPedido',
      'totalPedidoConDescuento',
      'totalDescuento',
      'cuponDescuentoAplicado',
      'linkWhatsApp',
      'productosPedido',
      'verZonasDelivery',
      'preguntasReqeridasCompletas'
    ]),

    habilitarEnviarWhatsApp() {
      return (
        !this.requestLinkMercadoPago &&
        this.pedido.nombre.length >= 3 &&
        (!this.pedido.envioDomicilio || this.pedido.domicilio.length >= 3) &&
        this.preguntasReqeridasCompletas
      )
    },

    leyendaComentario() {
      return this.empresa.leyenda_comentario || this.I18n.comentario_ayuda
    },

    tituloBotonVolver() {
      return this.pedido.envioDomicilio && this.verZonasDelivery
        ? this.I18n.volver_zonas_delivery
        : this.I18n.volver_pedido
    }
  },

  methods: {
    ...mapMutations([
      'SET_RECEPTOR',
      'SET_DOMICILIO',
      'SET_ENTRE_CALLES',
      'SET_COMENTARIO',
      'SET_CUPON_DESCUENTO',
      'SET_RESPUESTA_PREGUNTA'
    ]),

    onGenerarLinkMercadoPagoClick(checked) {
      this.$store.commit('SET_PAGO_MERCADOPAGO', checked)
      this.successLinkMercadoPago = false
      this.failureLinkMercadoPago = false
      if (checked) this.getLinkMercadoPago()
    },

    getLinkMercadoPago() {
      this.requestLinkMercadoPago = true
      this.$store
        .dispatch('generarLinkMercadoPago', this.$route.params.empresa)
        .then(() => {
          this.successLinkMercadoPago = true
        })
        .catch(() => {
          this.failureLinkMercadoPago = true
        })
        .finally(() => {
          this.requestLinkMercadoPago = false
        })
    },

    onGenerarLinkWompiClick(checked) {
      this.$store.commit('SET_PAGO_WOMPI', checked)
      this.successLinkWompi = false
      this.failureLinkWompi = false
      if (checked) this.getLinkWompi()
    },

    getLinkWompi() {
      this.requestingLinkWompi = true
      this.$store
        .dispatch('generarLinkWompi', this.$route.params.empresa)
        .then(() => {
          this.successLinkWompi = true
        })
        .catch(() => {
          this.successLinkWompi = false
        })
        .finally(() => {
          this.requestingLinkWompi = false
        })
    },

    onLinkWhatsAppClick(event) {
      if (!this.habilitarEnviarWhatsApp) event.preventDefault()
      else this.$store.dispatch('trackearPedido', this.$route.params.empresa)
    },

    onVolverPedido() {
      this.resetkMercadoPago()
      this.resetLinkWompi()

      if (this.pedido.envioDomicilio && this.verZonasDelivery)
        this.$router.push({
          name: 'ZonasDelivery',
          params: { empresa: this.$route.params.empresa }
        })
      else
        this.$router.push({
          name: 'ConfirmarPedido',
          params: { empresa: this.$route.params.empresa }
        })
    },

    resetkMercadoPago() {
      this.$store.commit('SET_LINK_MERCADOPAGO', '')
      this.$store.commit('SET_PAGO_MERCADOPAGO', false)
      this.successLinkMercadoPago = false
      this.failureLinkMercadoPago = false
    },

    resetLinkWompi() {
      this.$store.commit('SET_LINK_WOMPI', '')
      this.$store.commit('SET_PAGO_WOMPI', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1024px) {
  .container {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

.container {
  margin-bottom: 1rem;
}

.tachado {
  text-decoration: line-through;
}

#totales-container {
  font-weight: bold;
  padding: 0.8rem;
  margin-top: 1rem;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;

  i.fa {
    margin-right: 0.5rem;
  }

  & > div {
    display: flex;
    justify-content: space-between;
  }

  & > div:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.label {
  margin-bottom: 0;
}

label.forma-pago {
  margin-top: 1rem;
}

input[type='checkbox'] {
  margin-right: 0.3rem;
}

span#leyenda-mercado-pago {
  margin-right: 0.3rem;
}
</style>
