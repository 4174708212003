<template>
  <div>
    <HeaderEmpresa :empresa="empresa" :mostrarDatos="false" />

    <h2 style="font-weight: bold; text-align: center; margin-top: 1rem; margin-bottom: 1rem">
      <i class="fa fa-map-marked-alt" style="margin-right: 0.5rem"></i>
      {{ I18n.titulo_zona_delivery }}
    </h2>

    <div id="zonas-delivery">
      <div
        v-for="(zonaDelivery, index) in zonas_delivery"
        :key="zonaDelivery.id"
        class="zona_delivery"
      >
        <div class="has-text-centered">
          <img :src="zonaDelivery.imagen_url" alt="" />
        </div>

        <button @click.prevent="seleccionarZona(zonaDelivery)" class="button is-primary">
          <i class="fa fa-hand-point-right" style="margin-right: 0.5rem"></i>
          {{ zonaDelivery.nombre }}
          <span style="margin-left: 4rem">
            {{ displayCosto(zonaDelivery) }}
          </span>
        </button>

        <p class="descripcion">
          {{ zonaDelivery.descripcion }}
        </p>
        <hr v-if="true" />
      </div>

      <router-link
        :to="{ name: 'ConfirmarPedido' }"
        class="button is-warning"
        style="margin-top: 0rem; width: 100%; font-size: 1rem; font-weight: bold"
      >
        <i class="fa fa-undo" style="margin-right: 0.75rem"></i>
        {{ I18n.volver_al_pedido }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import HeaderEmpresa from '@src/tienda/components/header_empresa'
import { formatDecimal } from '@src/utils/format_decimal.js'

export default {
  components: { HeaderEmpresa },

  data() {
    return {
      I18n: serverExports.I18n
    }
  },

  computed: {
    ...mapState(['empresa', 'zonas_delivery']),
    ...mapGetters(['deliveryBonificado'])
  },

  methods: {
    displayCosto(zonaDelivery) {
      if (zonaDelivery.costo == 0 || this.deliveryBonificado) return 'GRATIS'
      else return formatDecimal(zonaDelivery.costo)
    },

    seleccionarZona(zonaDelivery) {
      this.$store.commit('SET_ZONA_DELIVERY', zonaDelivery)
      this.$router.push({ name: 'ConfirmarEnvio' })
    }
  }
}
</script>

<style lang="scss" scoped>
input,
textarea {
  margin-bottom: 1rem;
}

input[type='checkbox'] {
  margin-right: 0.3rem;
}

span#leyenda-mercado-pago {
  margin-right: 0.3rem;
}

#zonas-delivery {
  margin: 0.5rem;
  margin-bottom: 1rem;

  img {
    margin-bottom: 0.25rem;
  }

  p.descripcion {
    text-align: center;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  button {
    font-weight: bold;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1.3rem;
    background-color: #eee;
  }
}
</style>
