<template>
  <section class="seccion">
    <div
      v-if="seccion"
      @click="collapsed = !collapsed"
      :style="{ cursor: this.configuracionSecciones == 'no_contraer' ? 'default' : 'pointer' }"
      class="seccion-encabezado"
    >
      <div
        v-if="this.configuracionSecciones != 'no_contraer'"
        :class="{ expanded: !collapsed }"
        class="seccion-toggler"
      >
        <i v-if="collapsed" class="fa fa-lg fa-caret-left"></i>
        <i v-else class="fa fa-lg fa-caret-down"></i>
      </div>

      <a :name="stringToAnchor(seccion.nombre)"></a>

      <div v-if="seccion.imagen_128x128_url" class="imagen_seccion">
        <img :src="seccion.imagen_128x128_url" @click.stop="onImagenSeccionClick(seccion)" />
      </div>

      <h2
        style="font-weight: bold; text-align: center; margin-top: 1.25rem; margin-bottom: 1.25rem"
      >
        <i
          v-if="!seccion.imagen_128x128_url"
          class="fa fa-book-open"
          style="margin-right: 0.5rem"
        ></i>
        {{ seccion.nombre }}
      </h2>

      <p
        v-if="seccion.descripcion"
        class="has-text-centered"
        style="font-size: 0.8rem; margin-top: -0.75rem; margin-bottom: 1rem"
      >
        {{ seccion.descripcion }}
      </p>
    </div>

    <div v-if="verProductos" class="productos">
      <div
        v-for="(producto, index) in productos"
        :key="index"
        @click="onProductoClick(producto)"
        :class="{ clickeable: producto.cantidad == 0 }"
        class="producto box"
      >
        <div class="imagen_detalle_precio">
          <div
            v-if="producto.imagen_small_url"
            @click.stop="$emit('imagenClicked', producto.imagen_url)"
            class="imagen"
          >
            <img
              :src="producto.imagen_small_url"
              :class="{ 'recortar-imagenes': recortarImagenes }"
              loading="lazy"
            />
          </div>
          <div class="detalle">
            <div class="nombre">
              {{ producto.nombre }}
              <span v-if="producto.destacado" class="tag is-warning">
                {{ producto.destacado }}
              </span>
            </div>
            <div class="descripcion">
              {{ producto.descripcion }}
            </div>
          </div>
          <div class="precio">
            {{ precioProducto(producto) | formatDecimal }}
          </div>
        </div>

        <template v-if="producto.cantidad && producto.cantidad > 0 && !menuSalon">
          <div v-if="modalProducto.visualizar" class="control-editar">
            <button
              @click.prevent="$emit('showModalProducto', producto)"
              :title="I18n.editar"
              class="button is-primary"
            >
              <i class="fa fa-pen"></i>
              {{ producto.cantidad }} {{ leyenda(producto.cantidad) }}
            </button>
          </div>

          <div v-else class="controles-cantidad">
            <button
              @click.prevent.stop="onDisminuir(producto)"
              :title="I18n.disminuir"
              class="button disminuir is-primary is-small"
            >
              <i class="fa fa-minus"></i>
            </button>
            <div class="unidades">{{ producto.cantidad }} {{ leyenda(producto.cantidad) }}</div>
            <button
              @click.prevent="onIncrementar(producto)"
              :title="I18n.agregar"
              class="button incrementar is-primary is-small"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </template>
      </div>
    </div>

    <hr />
  </section>
</template>

<script>
export default {
  props: {
    seccion: {
      type: Object,
      required: false
    },

    productos: {
      type: Array,
      required: true
    },

    configuracionSecciones: {
      type: String,
      required: true
    },

    modalProducto: {
      type: Object,
      required: true
    },

    recortarImagenes: {
      type: Boolean,
      required: true
    },

    menuSalon: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      collapsed: this.configuracionSecciones == 'inicio_contraidas',
      I18n: serverExports.I18n
    }
  },

  computed: {
    verProductos() {
      return !this.seccion || this.configuracionSecciones == 'no_contraer' || !this.collapsed
    }
  },

  methods: {
    precioProducto(producto) {
      if (this.$route.name == 'VerMenu' && producto.precio_salon) return producto.precio_salon
      else return producto.precio
    },

    onImagenSeccionClick(seccion) {
      if (this.configuracionSecciones != 'no_contraer') this.collapsed = !this.collapsed
      else this.$emit('imagenClicked', seccion.imagen_url)
    },

    onProductoClick(producto) {
      if (!this.menuSalon && producto.cantidad == 0) {
        if (this.modalProducto.visualizar) this.$emit('showModalProducto', producto)
        else this.$store.commit('UPDATE_CANTIDAD', { producto, delta: 1 })
      }
    },

    onIncrementar(producto) {
      this.$store.commit('UPDATE_CANTIDAD', { producto, delta: 1 })
    },

    onDisminuir(producto) {
      this.$store.commit('UPDATE_CANTIDAD', { producto, delta: -1 })
    },

    leyenda(cantidad) {
      return cantidad == 1 ? this.I18n.unidad : this.I18n.unidades
    },

    stringToAnchor(string) {
      return string
        .replace(/ /g, '_')
        .replace(/[^a-z^A-Z^_]/g, '')
        .toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.seccion-encabezado {
  position: relative;
}

.seccion-toggler {
  position: absolute;
  top: -5px;
  right: 0;

  &.expanded {
    top: -8px;
  }
}

.imagen_seccion {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: -0.6rem;

  img {
    -o-box-shadow: 4px 4px 10px #555;
    -icab-box-shadow: 4px 4px 10px #555;
    -khtml-box-shadow: 4px 4px 10px #555;
    -moz-box-shadow: 4px 4px 10px #555;
    -webkit-box-shadow: 4px 4px 10px #555;
    box-shadow: 4px 4px 10px #555;
    max-width: 128px;
    max-height: 128px;
  }
}

.producto {
  padding: 1em;
  border: 1px solid #eee;
  margin-bottom: 1em !important;
}

.clickeable {
  cursor: pointer;
}

.imagen_detalle_precio {
  display: flex;

  .imagen {
    flex: 0 0 96px;
    margin-right: 0.5rem;
    cursor: zoom-in;

    img.recortar-imagenes {
      object-fit: cover;
      height: 96px;
      width: 96px;
    }
  }

  .detalle {
    flex: 1 1 auto;

    .nombre {
      font-size: 0.8rem;
      font-weight: bold;

      .tag {
        height: 1.2rem;
        font-size: 0.8rem;
        margin-left: 0.3rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
      }
    }

    .descripcion {
      font-size: 0.7rem;
    }
  }

  .precio {
    flex: 0 0 auto;
    margin-left: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: #00d1b2;
  }
}

.controles-cantidad {
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;

  .unidades {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 2rem;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.control-editar {
  text-align: center;
  margin-top: 0.75rem;

  i.fa {
    margin-right: 0.5rem;
  }
}

hr {
  margin: 1rem 0;
}
</style>
