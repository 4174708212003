import Vue from 'vue'

export function formatDecimal(value) {
  if (typeof value !== 'number') console.warn('formatDecimal should be called only with a Number')

  if (!isFinite(value) && Math.floor(value) !== value)
    console.warn('formatDecimal received a float Number')

  let options
  if (serverExports.empresa.pais == 'co')
    options = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  else options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  value = value / 100
  return `${serverExports.empresa.moneda} ${value.toLocaleString('us', options)}`
}

Vue.filter('formatDecimal', formatDecimal)
