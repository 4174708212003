<template>
  <div @click="hide" :class="{ 'is-active': visible }" class="modal">
    <div class="modal-background"></div>
    <div class="modal-content" style="padding: 0 20px">
      <p class="image">
        <img :src="imagenURL" />
      </p>
    </div>
    <button class="modal-close is-large" aria-label="close"></button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      imagenURL: ''
    }
  },

  methods: {
    show(imagenURL) {
      this.imagenURL = imagenURL
      this.visible = true
      document.documentElement.classList.add('is-clipped')
    },

    hide() {
      this.visible = false
      document.documentElement.classList.remove('is-clipped')
    }
  }
}
</script>

<style scoped>
.modal-background,
.modal-content {
  cursor: zoom-out;
}
</style>
