import Vue from 'vue'
import VueRouter from 'vue-router'
import SeleccionSucursal from '@src/tienda/pages/seleccion_sucursal'
import InicioTienda from '@src/tienda/pages/inicio_tienda'
import ConfirmarEnvio from '@src/tienda/pages/confirmar_envio'
import ConfirmarPedido from '@src/tienda/pages/confirmar_pedido'
import LandingTienda from '@src/tienda/pages/landing_tienda'
import ZonasDelivery from '@src/tienda/pages/zonas_delivery'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',

  routes: [
    { path: '/:empresa/start', name: 'InicioTienda', component: LandingTienda },
    {
      path: '/:empresa',
      name: 'PrepararPedido',
      component: InicioTienda
    },
    {
      path: '/:empresa/menu',
      name: 'VerMenu',
      component: InicioTienda
    },
    {
      path: '/:empresa/delivery',
      name: 'PrepararPedidoDelivery',
      component: InicioTienda
    },
    {
      path: '/:empresa/takeaway',
      name: 'PrepararPedidoTakeaway',
      component: InicioTienda
    },
    {
      path: '/:empresa/sucursales',
      name: 'SeleccionSucursal',
      component: SeleccionSucursal
    },
    {
      path: '/:empresa/pedido',
      name: 'ConfirmarPedido',
      component: ConfirmarPedido
    },
    {
      path: '/:empresa/zonas_delivery',
      name: 'ZonasDelivery',
      component: ZonasDelivery
    },
    {
      path: '/:empresa/enviar',
      name: 'ConfirmarEnvio',
      component: ConfirmarEnvio
    },
    {
      path: '/:empresa/:sucursal',
      name: 'PrepararPedidoSucursal',
      component: InicioTienda
    }
  ]
})

import store from '../store'

router.beforeEach((to, from, next) => {
  // si existen sucursales no visualizar tienda e ir a SeleccionSucursal
  if (to.name == 'PrepararPedido' && store.state.sucursales.length)
    next({ name: 'SeleccionSucursal', params: { empresa: to.params.empresa } })
  else if (to.name == 'PrepararPedido' && store.state.empresa.mostrar_inicio)
    next({ name: 'InicioTienda', params: { empresa: to.params.empresa } })

  // verificar existen sucursales para SeleccionSucursal
  if (to.name == 'SeleccionSucursal' && store.state.sucursales.length == 0)
    next({ name: 'PrepararPedido', params: { empresa: to.params.empresa } })

  // verificar existe sucursal en tienda
  if (
    to.name == 'PrepararPedidoSucursal' &&
    !store.state.sucursales.find((sucursal) => sucursal.url == to.params.sucursal)
  )
    next({ name: 'PrepararPedido', params: { empresa: to.params.empresa } })

  next()
})

export default router
