export default {
  empresa: serverExports.empresa,

  sucursales: serverExports.sucursales,

  productos: serverExports.productos,

  secciones: serverExports.secciones,

  zonas_delivery: serverExports.zonas_delivery,

  preguntas_visitante: serverExports.preguntas_visitante,

  pedido: {
    sucursal: null,
    productos: [],
    nombre: '',
    domicilio: '',
    entreCalles: '',
    comentario: '',
    envioDomicilio: true,
    menuSalon: false,
    cuponDescuento: '',
    pagoMercadoPago: false,
    linkMercadoPago: '',
    pagoWompi: false,
    linkWompi: '',
    zonaDelivery: null
  }
}
