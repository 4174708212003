<template>
  <div>
    <HeaderEmpresa :empresa="empresa" :sucursal="sucursal" :showAdministrarTienda="true" />

    <div class="container" style="margin-bottom: 4rem">
      <p v-if="empresa.introduccion" v-html="introduccionParrafo" id="introduccion"></p>

      <div v-if="!seccionesVisible" id="buscador" class="field has-addons">
        <div class="control">
          <!-- not using v-model becaouse of unexpected behaviour on mobile -->
          <!-- not updating binding on every keypress due to autocompletion -->
          <input
            :value="filtro"
            @input="filtro = $event.target.value"
            @keydown.enter="onBuscarFiltro($event)"
            :placeholder="I18n.buscador_ayuda"
            ref="filtro"
            id="filtro"
            type="search"
            class="input"
          />
        </div>
        <div class="control">
          <a @click.prevent="onClearFiltro($event)" href="#" id="limpiar_buscador" class="button">
            <i class="fa fa-times"></i>
          </a>
        </div>
      </div>

      <SeccionTienda
        v-for="seccion_id in seccionesIdsFiltradosOrdenados"
        :seccion="buscarSeccion(seccion_id)"
        :productos="productosPorSeccionFiltrados[seccion_id]"
        :configuracionSecciones="empresa.configuracion_secciones"
        :modalProducto="empresa.modal_producto"
        :recortarImagenes="empresa.recortar_imagenes_productos"
        :menuSalon="pedido.menuSalon"
        :key="seccion_id"
        @showModalProducto="$refs.modalProducto.show($event)"
        @imagenClicked="$refs.modalImagen.show($event)"
      />

      <div v-show="seccionesIdsFiltradosOrdenados.length == 0" class="has-text-centered my-5">
        <i class="fa fa-2x fa-exclamation-triangle"></i>
        <p class="mt-3">
          {{ I18n.busqueda_sin_restultados }}
        </p>
      </div>

      <div id="impulsado-por">
        <a :href="linkAgilPedido" target="_blank">
          {{ I18n.impulsado_por }} <i class="fa fa-bolt"></i> AgilPedido
        </a>
      </div>
    </div>

    <div v-if="!pedido.menuSalon" id="footer" :class="{ 'fondo-gris': !productosPedido.length }">
      <div class="container">
        <button
          v-if="productosPedido.length"
          @click.prevent="onPrepararEnvioClick"
          id="ver-pedido"
          class="button is-primary"
        >
          <span>
            <i class="fa fa-shopping-cart mr-1"></i>
            {{ I18n.ver_pedido }}
          </span>
          <span>{{ subtotalProductos | formatDecimal }}</span>
        </button>
        <div v-else id="arma-tu-pedido">
          <i class="fa fa-hand-point-up"></i>
          {{ I18n.selecciona_para_armar }}
        </div>
      </div>
    </div>

    <ModalProducto :empresa="empresa" ref="modalProducto" />
    <ModalImagen ref="modalImagen" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import groupBy from 'lodash.groupby'
import HeaderEmpresa from '@src/tienda/components/header_empresa'
import SeccionTienda from '@src/tienda/components/seccion_tienda'
import ModalProducto from '@src/tienda/components/modal_producto'
import ModalImagen from '@src/tienda/components/modal_imagen'

export default {
  components: { HeaderEmpresa, SeccionTienda, ModalProducto, ModalImagen },

  data() {
    return {
      seccionesVisible: false,
      filtro: '',
      I18n: serverExports.I18n
    }
  },

  created() {
    if (this.$route.params.sucursal) this.$store.commit('SET_SUCURSAL', this.sucursal)
  },

  beforeRouteEnter(to, from, next) {
    if (to.name == 'PrepararPedidoDelivery') next((vm) => vm.$store.commit('SET_ENVIO_DOMICILIO'))
    else if (to.name == 'PrepararPedidoTakeaway') next((vm) => vm.$store.commit('SET_RETIRO_LOCAL'))
    else if (to.name == 'VerMenu') next((vm) => vm.$store.commit('SET_MENU_SALON'))
    else next()
  },

  computed: {
    ...mapState(['empresa', 'sucursales', 'productos', 'secciones', 'pedido']),
    ...mapGetters(['productosPedido', 'subtotalProductos']),

    sucursal() {
      if (this.$route.params.sucursal)
        return this.sucursales.find((sucursal) => sucursal.url == this.$route.params.sucursal)
    },

    filtroLowercase() {
      return this.filtro.toLowerCase()
    },

    productosFiltrados() {
      if (this.filtro)
        return this.productos.filter(
          (producto) =>
            producto.nombre.toLowerCase().indexOf(this.filtroLowercase) > -1 ||
            (producto.descripcion &&
              producto.descripcion.toLowerCase().indexOf(this.filtroLowercase) > -1)
        )
      else return this.productos
    },

    productosPorSeccionFiltrados() {
      return groupBy(this.productosFiltrados, 'seccion_id')
    },

    seccionesIdsFiltradosOrdenados() {
      const sinSeccion = { id: null, nombre: '', posicion: -1 }
      return Object.keys(this.productosPorSeccionFiltrados)
        .map((seccion_id) => (seccion_id !== 'null' ? this.buscarSeccion(seccion_id) : sinSeccion))
        .sort((seccion1, seccion2) => seccion1.posicion - seccion2.posicion)
        .map((seccion) => seccion.id)
    },

    introduccionParrafo() {
      return this.empresa.introduccion.replace(/\n/g, '<br>')
    },

    linkAgilPedido() {
      return `/${this.empresa.pais}`
    }
  },

  methods: {
    onBuscarFiltro(event) {
      event.target.blur()
    },

    onClearFiltro(event) {
      this.filtro = ''
      // elimina recuadro azul de foco
      event.target.blur()
    },

    buscarSeccion(id) {
      return this.secciones.find((seccion) => seccion.id == id)
    },

    onOpenNavbar() {
      this.filtro = ''
      this.seccionesVisible = !this.seccionesVisible
    },

    onPrepararEnvioClick() {
      this.$router.push({ name: 'ConfirmarPedido' })
    },

    stringToAnchor(string) {
      return string
        .replace(/ /g, '_')
        .replace(/[^a-z^A-Z^_]/g, '')
        .toLowerCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1024px !important;
}

@media (max-width: 1024px) {
  .container {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

p#introduccion {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
  font-style: italic;
}

#buscador {
  margin-top: 1rem;
  margin-bottom: 1rem;

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .control:nth-child(1) {
    width: 100%;
  }
}

button#ver-pedido {
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}

#impulsado-por {
  text-align: center;
  font-size: 0.7rem;

  a {
    color: black;
  }

  i.fa {
    margin-left: 0.3rem;
  }
}

#footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: white;
  padding: 0.75rem;

  &.fondo-gris {
    background-color: #f5f5f5;
  }

  #arma-tu-pedido {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;

    i.fa {
      margin-right: 0.5rem;
    }
  }
}
</style>
