import uniq from 'lodash.uniq'
import { formatDecimal } from '@src/utils/format_decimal.js'

const I18n = serverExports.I18n.pedido

export default {
  verZonasDelivery(state) {
    return state.zonas_delivery.length > 0
  },

  subtotalProductos(_, getters) {
    let subtotal = 0
    getters.productosPedido.forEach((producto) => (subtotal += producto.subtotal))
    return subtotal
  },

  deliveryBonificado(state, getters) {
    if (state.empresa.minimo_delivery_sin_cargo !== null)
      return getters.subtotalProductos >= state.empresa.minimo_delivery_sin_cargo
  },

  costoEnvio(state, getters) {
    if (state.pedido.envioDomicilio && !getters.deliveryBonificado) {
      if (state.zonas_delivery.length == 0) return state.empresa.costo_envio
      else if (state.pedido.zonaDelivery !== null) return state.pedido.zonaDelivery.costo
    }
    return 0
  },

  totalPedido(_, getters) {
    return getters.subtotalProductos + getters.costoEnvio
  },

  totalPedidoConDescuento(_, getters) {
    return getters.totalPedido - getters.totalDescuento
  },

  totalDescuento(_, getters) {
    if (getters.cuponDescuentoAplicado)
      return Math.trunc((getters.totalPedido * getters.cuponDescuentoAplicado.descuento) / 100)
    else return 0
  },

  productosPedido(state) {
    return state.productos.filter((producto) => producto.cantidad > 0)
  },

  preguntasRespondidas(state) {
    return state.preguntas_visitante.filter(
      (pregunta) => 'respuesta' in pregunta && pregunta.respuesta.length > 0
    )
  },

  cuponDescuentoAplicado(state, getters) {
    return state.empresa.cupones_descuento.find(
      (cupon) => cupon.codigo.toLowerCase() == state.pedido.cuponDescuento.toLowerCase()
    )
  },

  textoPedidos(state, getters) {
    let pedido = ''
    getters.productosPedido.forEach((producto) => {
      pedido += `- ${producto.cantidad} x ${producto.nombre} (${formatDecimal(
        producto.subtotal
      )})\n`
      if (producto.comentario) pedido += `  ${producto.comentario}\n\n`
    })

    if (state.pedido.envioDomicilio) {
      if (getters.verZonasDelivery)
        pedido += `- Delivery ${state.pedido.zonaDelivery.nombre} (${formatDecimal(
          getters.costoEnvio
        )})\n`
      else if (state.empresa.costo_envio > 0)
        pedido += `- ${I18n.costo_delivery} (${formatDecimal(getters.costoEnvio)})\n`
    }

    pedido += `\n*${I18n.total}: ${formatDecimal(getters.totalPedido)}*\n`

    if (getters.cuponDescuentoAplicado) {
      pedido += `*Descuento (${getters.cuponDescuentoAplicado.descuento}%): ${formatDecimal(
        getters.totalDescuento
      )}*\n`
      pedido += `*Total final: ${formatDecimal(getters.totalPedidoConDescuento)}*\n`
    }

    pedido += '\n'

    getters.preguntasRespondidas.forEach((pregunta) => {
      pedido += `${pregunta.titulo}: ${pregunta.respuesta}\n\n`
    })

    if (state.pedido.comentario) pedido += `${I18n.comentario}: ${state.pedido.comentario}\n\n`

    if (state.pedido.pagoMercadoPago && state.pedido.linkMercadoPago)
      pedido += `Link para MercadoPago: ${state.pedido.linkMercadoPago}\n\n`

    if (state.pedido.pagoWompi && state.pedido.linkWompi)
      pedido += `Link de pago Wompi: ${state.pedido.linkWompi}\n\n`

    return pedido
  },

  mensajeModalidad(state, getters) {
    if (state.pedido.envioDomicilio)
      return `${I18n.delivery} *${state.pedido.domicilio}${getters.textoEntreCalles}*`
    else if (state.pedido.sucursal) return `${I18n.takeaway} ${state.pedido.sucursal.domicilio}`
    else return `${I18n.takeaway} ${state.empresa.domicilio}`
  },

  textoEntreCalles(state) {
    return state.pedido.entreCalles.length ? ` entre ${state.pedido.entreCalles}` : ''
  },

  nombreFantasiaDestino(state) {
    return state.pedido.sucursal
      ? state.pedido.sucursal.nombre_fantasia
      : state.empresa.nombre_fantasia
  },

  textoWhatsApp(state, getters) {
    return (
      `${I18n.hola} ${getters.nombreFantasiaDestino}! ${I18n.soy} *${state.pedido.nombre}* ` +
      `${I18n.hacer_pedido} ${getters.mensajeModalidad}:\n\n` +
      `${getters.textoPedidos}${I18n.gracias}!`
    )
  },

  linkWhatsApp(state, getters) {
    let whatsapp = state.pedido.sucursal ? state.pedido.sucursal.whatsapp : state.empresa.whatsapp
    return `https://wa.me/${whatsapp}?text=${encodeURIComponent(getters.textoWhatsApp)}`
  },

  preguntasReqeridasCompletas(state) {
    let respuestasRequediras = state.preguntas_visitante.filter((pregunta) => pregunta.requerido)
    return respuestasRequediras.every(
      (pregunta) => 'respuesta' in pregunta && pregunta.respuesta.length > 0
    )
  }
}
