<template>
  <div id="background" :style="logoBackground">
    <div id="filtro-blur">
      <div id="content">
        <div id="logo">
          <img :src="empresa.imagen_url" />
        </div>

        <div id="botonera">
          <router-link
            v-if="empresa.delivery_habilitado"
            :to="{ name: 'PrepararPedidoDelivery' }"
            class="button is-primary is-medium"
          >
            <i class="fa fa-motorcycle"></i>
            {{ empresa.leyenda_inicio_delivery || I18n.delivery }}
          </router-link>
          <router-link
            v-if="empresa.takeaway_habilitado"
            :to="{ name: 'PrepararPedidoTakeaway' }"
            class="button is-primary is-medium"
          >
            <i class="fa fa-store"></i>
            {{ empresa.leyenda_inicio_takeaway || I18n.takeaway }}
          </router-link>
          <router-link
            v-if="empresa.menu_salon_habilitado"
            :to="{ name: 'VerMenu' }"
            class="button is-primary is-medium"
          >
            <i class="fa fa-book-open"></i>
            {{ this.empresa.leyenda_inicio_menu_salon || I18n.menu_salon }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      I18n: serverExports.I18n
    }
  },

  created() {
    document.body.style.minHeight = '100vh'
    document.documentElement.style.minHeight = '100vh'
  },

  destroyed() {
    document.body.style.minHeight = null
    document.documentElement.style.minHeight = '100vh'
  },

  computed: {
    ...mapState(['empresa']),

    logoBackground() {
      if (this.empresa.fondo_inicio_url)
        return {
          'background-image': `url(${this.empresa.fondo_inicio_url})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center',
          'background-size': 'cover'
        }
      else return { background: '#eee' }
    }
  }
}
</script>

<style lang="scss" scbackgroundoped>
#background,
#filtro-blur {
  min-height: 100vh;
}

#logo {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;

  img {
    height: 164px;
    width: 164px;
    border-radius: 290486px;
  }
}

#filtro-blur {
  backdrop-filter: blur(2px);
  padding-top: 30vh;
}

#botonera a {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  width: 300px;
}

i.fa {
  margin-right: 0.3rem;
}
</style>
