<template>
  <div>
    <HeaderEmpresa :empresa="empresa" :mostrarDatos="false" />

    <div class="container">
      <template v-if="!empresa.mostrar_inicio">
        <h2 style="font-weight: bold; text-align: center; margin-top: 1.75rem; margin-bottom: 1rem">
          <i class="fa fa-motorcycle" style="margin-right: 0.5rem"></i>
          {{ I18n.modalidad_envio }}
        </h2>

        <div style="display: flex; justify-content: space-between; margin-bottom: 1.75rem">
          <button
            @click="SET_ENVIO_DOMICILIO"
            id="envio_domicilio"
            style="width: 100%; margin-right: 0.3rem"
            class="button is-small is-warning"
          >
            <span v-if="pedido.envioDomicilio">
              <i class="fa fa-check" style="margin-right: 0.5rem"></i>
            </span>
            {{ I18n.envio_domicilio }}
          </button>
          <button
            @click="SET_RETIRO_LOCAL"
            id="retiro_local"
            style="width: 100%; margin-left: 0.3rem"
            class="button is-small is-warning"
          >
            <span v-if="!pedido.envioDomicilio">
              <i class="fa fa-check" style="margin-right: 0.5rem"></i>
            </span>
            {{ I18n.retiro_local }}
          </button>
        </div>
      </template>

      <DetallePedido
        :productos="productosPedido"
        :envioDomicilio="pedido.envioDomicilio"
        :costoEnvio="costoEnvio"
        :totalPedido="totalPedido"
      />

      <div v-if="!superaCompraMinima" id="compra-minima" class="notification is-danger">
        El pedido debe superar la compra mínima de
        <span>{{ empresa.compra_minima | formatDecimal }}</span
        >.
      </div>

      <div v-show="deliveryBonificado" class="notification notificacion has-text-centered">
        <i class="fa fa-check"></i>{{ I18n.pedido_bonificado }}
      </div>

      <button
        @click.prevent="onConfirmarEnvio"
        :disabled="!superaCompraMinima"
        class="button is-primary"
        style="margin-top: 1.75rem; width: 100%; font-size: 1rem; font-weight: bold"
      >
        <i class="fa fa-clipboard-check" style="margin-right: 0.75rem"></i>
        {{ I18n.confirmar_pedido }}
      </button>
      <a
        @click.prevent="onVolverAMenu"
        href="#"
        class="button is-warning"
        style="margin-top: 1rem; width: 100%; font-size: 1rem; font-weight: bold"
      >
        <i class="fa fa-undo" style="margin-right: 0.75rem"></i>
        {{ I18n.volver_menu }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import HeaderEmpresa from '@src/tienda/components/header_empresa'
import DetallePedido from '@src/tienda/components/detalle_pedido'

export default {
  components: { HeaderEmpresa, DetallePedido },

  data() {
    return {
      I18n: serverExports.I18n
    }
  },

  created() {
    if (this.productosPedido.length == 0)
      this.$router.push({ name: 'PrepararPedido', params: { empresa: this.$route.params.empresa } })
  },

  computed: {
    ...mapState(['empresa', 'pedido']),
    ...mapGetters([
      'productosPedido',
      'subtotalProductos',
      'costoEnvio',
      'deliveryBonificado',
      'totalPedido',
      'verZonasDelivery',
      'linkWhatsApp'
    ]),

    superaCompraMinima() {
      return (
        !this.pedido.envioDomicilio ||
        this.empresa.compra_minima === null ||
        this.subtotalProductos >= this.empresa.compra_minima
      )
    }
  },

  methods: {
    ...mapMutations(['SET_ENVIO_DOMICILIO', 'SET_RETIRO_LOCAL']),

    onConfirmarEnvio() {
      if (this.pedido.envioDomicilio && this.verZonasDelivery)
        this.$router.push({
          name: 'ZonasDelivery',
          params: { empresa: this.$route.params.empresa }
        })
      else
        this.$router.push({
          name: 'ConfirmarEnvio',
          params: { empresa: this.$route.params.empresa }
        })
    },

    onVolverAMenu() {
      if (this.pedido.sucursal)
        this.$router.push({
          name: 'PrepararPedidoSucursal',
          params: { empresa: this.$route.params.empresa, sucursal: this.pedido.sucursal.url }
        })
      else if (this.empresa.mostrar_inicio)
        if (this.empresa.delivery_habilitado)
          this.$router.push({
            name: 'PrepararPedidoDelivery',
            params: { empresa: this.$route.params.empresa }
          })
        else
          this.$router.push({
            name: 'PrepararPedidoTakeaway',
            params: { empresa: this.$route.params.empresa }
          })
      else
        this.$router.push({
          name: 'PrepararPedido',
          params: { empresa: this.$route.params.empresa }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1024px) {
  .container {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

#compra-minima {
  text-align: center;
  font-size: 0.9rem;
  padding: 0.75rem;
  margin-top: 1rem;
  margin-bottom: -0.5rem;

  span {
    white-space: nowrap;
  }
}

.notificacion {
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: -0.5rem;
  padding: 0.8rem;

  i.fa {
    margin-right: 0.5rem;
  }
}
</style>
