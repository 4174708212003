<template>
  <section id="detalle_pedido">
    <h2 style="font-weight: bold; text-align: center; margin-top: 1rem; margin-bottom: 1rem">
      <i class="fa fa-shopping-basket" style="margin-right: 0.5rem"></i>
      {{ I18n.controlar_pedido }}
    </h2>

    <template v-for="producto in productos">
      <div class="renglon">
        <div>{{ producto.cantidad }} x {{ producto.nombre }}</div>
        <div class="importe">
          {{ producto.subtotal | formatDecimal }}
        </div>
      </div>
      <div v-if="producto.comentario" class="comentario">
        {{ producto.comentario }}
      </div>
    </template>

    <div v-if="!verZonasDelivery && envioDomicilio && empresa.costo_envio > 0" class="renglon">
      <div>
        {{ I18n.envio_domicilio }}
      </div>
      <div class="importe">
        {{ costoEnvio | formatDecimal }}
      </div>
    </div>

    <hr />

    <div id="total">
      <div>{{ I18n.pedido.total }}</div>
      <div class="importe">
        {{ totalPedido | formatDecimal }}
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    productos: {
      type: Array,
      required: true
    },

    envioDomicilio: {
      type: Boolean,
      required: true
    },

    costoEnvio: {
      type: Number,
      required: true
    },

    totalPedido: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      I18n: serverExports.I18n
    }
  },

  computed: {
    ...mapState(['empresa']),
    ...mapGetters(['verZonasDelivery'])
  }
}
</script>

<style lang="scss" scoped>
.renglon {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bold;
}

.comentario {
  font-size: 0.85rem;
  margin-bottom: 0.6rem;
}

#total {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bold;
}

.importe {
  color: #00d1b2;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
