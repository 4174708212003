<template>
  <div class="has-background-light" style="padding-top: 0.75rem; padding-bottom: 0.75rem">
    <div id="encabezado" class="container">
      <router-link
        v-if="verVolverInicio"
        :to="{ name: 'InicioTienda' }"
        id="volver-a-inicio"
        title="Administrar tienda"
      >
        <i class="fa fa-chevron-left"></i>
      </router-link>

      <a
        v-if="showAdministrarTienda"
        :href="administrarTiendaLink()"
        id="administrar-tienda"
        title="Administrar tienda"
      >
        <i class="fa fa-wrench"></i>
      </a>

      <div style="display: flex; justify-content: space-around">
        <figure class="image is-96x96" style="margin-bottom: 0.5rem">
          <img class="is-rounded" :src="empresa.imagen_url" />
        </figure>
      </div>

      <h1 class="nombre">
        {{ nombreFantasia }}
      </h1>

      <div v-if="mostrarDatos" id="datos-empresa">
        <div>
          <i class="fa fa-map-marker-alt"></i>
          {{ ubicacion }}
          <span :class="iconoPais" class="flag-icon"></span>
        </div>
        <div v-if="leyendaHorario">
          <i class="fa fa-clock"></i>
          {{ leyendaHorario }}
        </div>
        <div v-if="empresa.leyenda_delivery">
          <i class="fa fa-motorcycle"></i>
          {{ empresa.leyenda_delivery }}
        </div>
        <div v-if="empresa.leyenda_forma_pago">
          <i class="fa fa-handshake"></i>
          {{ empresa.leyenda_forma_pago }}
        </div>
        <div id="redes-telefono">
          <a v-if="empresa.instagram" :href="instagramLink">
            <i class="fab fa-instagram"></i>{{ empresa.instagram }}
          </a>
          <a v-if="empresa.facebook" :href="facebookLink">
            <i class="fab fa-facebook"></i>{{ empresa.facebook }}
          </a>
          <a v-if="telefono" :href="telefonoLink"> <i class="fas fa-phone"></i>{{ telefono }} </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    empresa: {
      type: Object,
      required: true
    },

    sucursal: {
      type: Object
    },

    showAdministrarTienda: {
      type: Boolean,
      default: false
    },

    mostrarDatos: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    nombreFantasia() {
      return this.sucursal ? this.sucursal.nombre_fantasia : this.empresa.nombre_fantasia
    },

    ubicacion() {
      if (this.sucursal) return `${this.sucursal.domicilio}, ${this.sucursal.localidad}`
      else if (this.empresa.domicilio) return `${this.empresa.domicilio}, ${this.empresa.localidad}`
      else return this.empresa.localidad
    },

    iconoPais() {
      return 'flag-icon-' + this.empresa.pais
    },

    instagramLink() {
      return `https://instagram.com/${this.empresa.instagram}`
    },

    facebookLink() {
      return `https://facebook.com/${this.empresa.facebook}`
    },

    telefono() {
      return this.sucursal ? this.sucursal.telefono : this.empresa.telefono
    },

    telefonoLink() {
      if (this.telefono) return `tel:${this.telefono.replace(/\D/g, '')}`
    },

    leyendaHorario() {
      return this.sucursal ? this.sucursal.leyenda_horario : this.empresa.leyenda_horario
    },

    verVolverInicio() {
      return (
        this.empresa.mostrar_inicio &&
        ['VerMenu', 'PrepararPedidoDelivery', 'PrepararPedidoTakeaway'].includes(this.$route.name)
      )
    }
  },

  methods: {
    administrarTiendaLink() {
      return `/${this.$route.params.empresa}/panel_control`
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 1024px !important;
}

#volver-a-inicio {
  color: black;
  position: absolute;
  left: 4px;
  top: 0;
  cursor: pointer;
}

#administrar-tienda {
  color: black;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.nombre {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}

span.flag-icon {
  margin-left: 0.2rem;
}

#datos-empresa {
  text-align: center;
  font-size: 0.8rem;

  i {
    margin-right: 0.15rem;
  }

  div {
    margin-top: 0.2rem;
  }

  a {
    color: #4a4a4a;
  }
}

#redes-telefono {
  a:nth-child(n + 2) {
    margin-left: 0.5rem;
  }

  i {
    margin-right: 0.15rem;
  }
}
</style>
