import axios from 'axios'

export default {
  generarLinkMercadoPago({ state, commit, getters }, tienda) {
    if (!state.pedido.linkMercadoPago) {
      let endpoint = `/${tienda}/link_mercadopago`
      return axios
        .post(endpoint, { total_pedido: getters.totalPedidoConDescuento })
        .then((response) => {
          commit('SET_LINK_MERCADOPAGO', response.data.link_mercadopago)
        })
    }
  },

  generarLinkWompi({ state, commit, getters }, tienda) {
    if (!state.pedido.linkWompi) {
      let endpoint = `/${tienda}/link_wompi`
      return axios
        .post(endpoint, { total_pedido: getters.totalPedidoConDescuento })
        .then((response) => {
          commit('SET_LINK_WOMPI', response.data.link_wompi)
        })
    }
  },

  trackearPedido({ getters }, tienda) {
    return axios.post(`/${tienda}/trackear`, { data: { total: getters.totalPedidoConDescuento } })
  }
}
