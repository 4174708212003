import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@src/tienda/router'
import store from '@src/tienda/store'
import '@src/utils/format_decimal'

window.addEventListener('load', () => {
  new Vue({
    router,

    store,

    el: '#loader',

    render(h) {
      return h('router-view')
    }
  })
})
