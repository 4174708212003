import Vue from 'vue'

export default {
  SET_SUCURSAL(state, sucursal) {
    state.pedido.sucursal = sucursal
  },

  UPDATE_CANTIDAD(_, { producto, delta }) {
    producto.cantidad += delta
    Vue.set(producto, 'subtotal', producto.precio * producto.cantidad)
  },

  SET_PRODUCTO_COMENTARIO(_, { producto, comentario }) {
    Vue.set(producto, 'comentario', comentario)
  },

  SET_MENU_SALON(state) {
    state.pedido.menuSalon = true
  },

  SET_ENVIO_DOMICILIO(state) {
    state.pedido.menuSalon = false
    state.pedido.envioDomicilio = true
  },

  SET_RETIRO_LOCAL(state) {
    state.pedido.menuSalon = false
    state.pedido.envioDomicilio = false
  },

  SET_ZONA_DELIVERY(state, zonaDelivery) {
    state.pedido.zonaDelivery = zonaDelivery
  },

  SET_RECEPTOR(state, receptor) {
    state.pedido.nombre = receptor
  },

  SET_DOMICILIO(state, domicilio) {
    state.pedido.domicilio = domicilio
  },

  SET_ENTRE_CALLES(state, entreCalles) {
    state.pedido.entreCalles = entreCalles
  },

  SET_COMENTARIO(state, comentario) {
    state.pedido.comentario = comentario
  },

  SET_CUPON_DESCUENTO(state, cupon_descuento) {
    state.pedido.cuponDescuento = cupon_descuento
  },

  SET_PAGO_MERCADOPAGO(state, value) {
    state.pedido.pagoMercadoPago = value
  },

  SET_LINK_MERCADOPAGO(state, link) {
    state.pedido.linkMercadoPago = link
  },

  SET_PAGO_WOMPI(state, value) {
    state.pedido.pagoWompi = value
  },

  SET_LINK_WOMPI(state, link) {
    state.pedido.linkWompi = link
  },

  SET_RESPUESTA_PREGUNTA(state, { index, respuesta }) {
    Vue.set(state.preguntas_visitante[index], 'respuesta', respuesta)
  }
}
