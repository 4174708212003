<template>
  <div id="modal-producto" class="modal" :class="{ 'is-active': visible }">
    <div @click="hide" class="modal-background"></div>

    <div v-if="producto" class="modal-content box">
      <button @click.prevent="hide" title="Cerrar" class="delete is-medium"></button>

      <div v-if="producto.imagen_small_url" class="img-container">
        <img :src="producto.imagen_small_url" />
      </div>
      <div class="nombre">
        {{ producto.nombre }}
      </div>
      <div class="descripcion">
        {{ producto.descripcion }}
      </div>
      <div class="precio">
        {{ producto.precio | formatDecimal }}
      </div>

      <div v-if="producto.cantidad == 0" class="control-agregar">
        <button @click.prevent="onIncrementar(producto)" class="button is-primary">
          <i class="fa fa-plus"></i> {{ I18n.agregar }}
        </button>
      </div>
      <div v-else class="controles-cantidad">
        <button
          @click.prevent.stop="onDisminuir(producto)"
          :title="I18n.disminuir"
          class="button disminuir is-primary is-small"
        >
          <i class="fa fa-minus"></i>
        </button>
        <div class="unidades">{{ producto.cantidad }} {{ leyenda(producto.cantidad) }}</div>
        <button
          @click.prevent="onIncrementar(producto)"
          :title="I18n.aumentar"
          class="button incrementar is-primary is-small"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>

      <template v-if="producto.cantidad > 0">
        <label for="producto_comentario" class="detalle label">
          {{ empresa.modal_producto.etiqueta_comentario }}:
        </label>
        <div class="control">
          <textarea
            :value="producto.comentario"
            @input="onComentarioInput($event.target.value)"
            id="producto_comentario"
            class="textarea"
            rows="3"
            maxlength="300"
            :placeholder="empresa.modal_producto.sugerencia_comentario"
          >
          </textarea>
          <p class="help has-text-right">{{ producto.comentario.length }} / 300</p>
        </div>
      </template>

      <div v-if="producto.cantidad > 0" class="has-text-centered">
        <button @click.prevent="hide" class="button is-primary" id="confirmar">
          <i class="fa fa-check"></i>
          {{ I18n.confirmar }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    empresa: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      visible: false,
      producto: null,
      I18n: serverExports.I18n
    }
  },

  methods: {
    show(producto) {
      if (typeof producto.comentario == 'undefined')
        this.$store.commit('SET_PRODUCTO_COMENTARIO', { producto, comentario: '' })

      this.producto = producto

      this.visible = true
      document.documentElement.classList.add('is-clipped')
    },

    hide() {
      this.visible = false
      document.documentElement.classList.remove('is-clipped')
    },

    onComentarioInput(value) {
      this.$store.commit('SET_PRODUCTO_COMENTARIO', { producto: this.producto, comentario: value })
    },

    onIncrementar(producto) {
      this.$store.commit('UPDATE_CANTIDAD', { producto, delta: 1 })
    },

    onDisminuir(producto) {
      this.$store.commit('UPDATE_CANTIDAD', { producto, delta: -1 })
    },

    leyenda(cantidad) {
      return cantidad == 1 ? this.I18n.unidad : this.I18n.unidades
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  width: calc(100vw - 1.5rem);
  max-width: 600px;
  max-height: none;
}

button.delete {
  position: absolute;
  top: 12px;
  right: 12px;
}

.img-container {
  text-align: center;
  margin-bottom: 0.75rem;
}

.nombre {
  font-weight: bold;
  margin-bottom: 0.75rem;
}

.descripcion {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.precio {
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: right;
  font-weight: bold;
  color: #00d1b2;
}

label.detalle {
  font-size: 0.85rem;
  margin-top: 1rem;
}

.control-agregar {
  margin-top: 1rem;
  text-align: center;

  button {
    font-size: 0.9rem;
  }

  i.fa {
    margin-right: 0.5rem;
  }
}

.controles-cantidad {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .unidades {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 2rem;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  i.fa {
    margin-right: 0;
  }
}

button#confirmar {
  font-size: 0.9rem;

  i.fa {
    margin-right: 0.5rem;
  }
}

textarea {
  font-size: 0.85rem;
}
</style>
